import React, { Component } from "react";
import Slider from "react-slick";
import classes from "./style.module.scss";
import testimonialpm from "../../assets/testimonialpm.svg";
import testimonialdt from "../../assets/testimonialdt.svg";
import testimonialcg from "../../assets/testimonialcg.svg";
import testimoniala from "../../assets/testimoniala.svg";
import testimonialni from "../../assets/testimonialni.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default class Carousel extends Component {
  state = {
    display: true,
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      adaptiveHeight: false,
      variableWidth: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "10px",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      //   <div style={{ border: "solid", borderWidth: 1 }}>
      <Slider {...settings} className={classes["slick-next"]}>
        <div className={classes["purple-item"]}>
          <img
            src={testimonialcg}
            alt="axis-testimonial"
            className={classes["purple-image"]}
          />
        </div>
        <div className={classes["purple-item"]}>
          <img
            src={testimonialdt}
            alt="axis-testimonial"
            className={classes["purple-image"]}
          />
        </div>
        <div className={classes["purple-item"]}>
          <img
            src={testimoniala}
            alt="axis-testimonial"
            className={classes["purple-image"]}
          />
        </div>
        <div className={classes["purple-item"]}>
          <img
            src={testimonialni}
            alt="axis-testimonial"
            className={classes["purple-image"]}
          />
        </div>

        <div className={classes["purple-item"]}>
          <img
            src={testimonialpm}
            alt="axis-testimonial"
            className={classes["purple-image"]}
          />
        </div>
      </Slider>
      //   </div>
    );
  }
}
