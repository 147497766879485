import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Legal from "./pages/Legal";
import SuccessMonthly from "./pages/SuccessMonthly";
import SuccessAnnually from "./pages/SuccessAnnually";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/successmonthly" element={<SuccessMonthly />} />
        <Route path="/successannually" element={<SuccessAnnually />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
    </>
  );
};

export default App;
