import React from "react";
import classes from "./style.module.scss";

const Legal = () => {
  return (
    <div className={classes.body}>
      <h1 className={classes.title}>Disclaimer</h1>
      <p className={classes.description}>
        Updated at 2022-10-07
        <br />
        Axis Research and Publishing Center FZCO hereby grants you access to
        www.heyaxis.com (“the Website”) and invites you to purchase the services
        offered here.
      </p>

      <h1 className={classes.title}>Definitions and Key Terms</h1>
      <p className={classes.description}>
        To help explain things as clearly as possible in this Disclaimer, every
        time any of these terms are referenced, are strictly defined as:
      </p>
      <ul className={classes["list-wrapper"]}>
        <li className={classes["list-item"]}>
          Cookie: small amount of data generated by a website and saved by your
          web browser. It is used to identify your browser, provide analytics,
          remember information about you such as your language preference or
          login information.{" "}
        </li>
        <li className={classes["list-item"]}>
          Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
          refers to Axis Reseach and Publsihing Center FZCO, Dubai Silicon Oasis
          that is responsible for your information under this Disclaimer.{" "}
        </li>
        <li className={classes["list-item"]}>
          Service: refers to the service provided by Axis Research and
          Publishing Center FZCO as described in the relative terms (if
          available) and on this platform.
        </li>
        <li className={classes["list-item"]}>
          Website: Axis Research and Publishing Center FZCO."’s" site, which can
          be accessed via this URL: www.heyaxis.com
        </li>
        <li className={classes["list-item"]}>
          You: a person or entity that is registered with Axis Research and
          Publishing Center FZCO to use the Services.
        </li>
      </ul>

      <h1 className={classes.title}>Limited Liability</h1>
      <p className={classes.description}>
        Axis Research and Publishing Center FZCO endeavours to update and/or
        supplement the content of the website on a regular basis. Despite our
        care and attention, content may be incomplete and/or incorrect.
        <br />
        The materials offered on the website are offered without any form of
        guarantee or claim to their correctness. These materials can be changed
        at any time without prior notice from Axis Research and Publishing
        Center FZCO.
        <br />
        Particularly, all prices on the website are stated subject to typing and
        programming errors. No liability is assumed for the implications of such
        errors. No agreement is concluded on the basis of such errors.
        <br />
        Axis Research and Publishing Center FZCO shall not bear any liability
        for hyperlinks to websites or services of third parties included on the
        website. From our website, you can visit other websites by following
        hyperlinks to such external sites. While we strive to provide only
        quality links to useful and ethical websites, we have no control over
        the content and nature of these sites.
        <br />
        These links to other websites do not imply a recommendation for all the
        content found on these sites. Site owners and content may change without
        notice and may occur before we have the opportunity to remove a link
        which may have gone ‘bad’.
        <br />
        Please be also aware that when you leave our website, other sites may
        have different privacy policies and terms which are beyond our control.
        Please be sure to check the Privacy Policies of these sites as well as
        their "Terms of Service" before engaging in any business or uploading
        any information.
      </p>

      <h1 className={classes.title}>Links to other Websites Disclaimer</h1>

      <p className={classes.description}>
        This Disclaimer applies only to the Services. The Services may contain
        links to other websites not operated or controlled by Axis Research and
        Publishing Center FZCO. We are not responsible for the content, accuracy
        or opinions expressed in such websites, and such websites are not
        investigated, monitored or checked for accuracy or completeness by us.
        Please remember that when you use a link to go from the Services to
        another website, our Privacy Policy is no longer in effect. Your
        browsing and interaction on any other website, including those that have
        a link on our platform, is subject to that website’s own rules and
        policies. Such third parties may use their own cookies or other methods
        to collect information about you. If You click on a third party link,
        You will be directed to that third party's site. We strongly advise You
        to review the Privacy Policy and Terms of every site You visit.
        <br />
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h1 className={classes.title}>Errors and Omissions Disclaimer</h1>

      <p className={classes.description}>
        Axis Research and Publishing Center FZCO is not responsible for any
        content, code or any other imprecision.
        <br />
        Axis Research and Publishing Center FZCO does not provide warranties or
        guarantees.
        <br />
        In no event shall Axis Research and Publishing Center FZCO be liable for
        any special, direct, indirect, consequential, or incidental damages or
        any damages whatsoever, whether in an action of contract, negligence or
        other tort, arising out of or in connection with the use of the Service
        or the contents of the Service. Axis Research and Publishing Center FZCO
        reserves the right to make additions, deletions, or modifications to the
        contents on the Service at any time without prior notice.
      </p>

      <h1 className={classes.title}>General Disclaimer</h1>
      <p className={classes.description}>
        The Axis Research and Publishing Center FZCO Service and its contents
        are provided "as is" and "as available" without any warranty or
        representations of any kind, whether express or implied. Axis Research
        and Publishing Center FZCO is a distributor and not a publisher of the
        content supplied by third parties; as such, Axis Research and Publishing
        Center FZCO exercises no editorial control over such content and makes
        no warranty or representation as to the accuracy, reliability or
        currency of any information, content or service provided through or
        accessible via the Axis Research and Publishing Center FZCO Service.
        Without limiting the foregoing, Axis Research and Publishing Center FZCO
        specifically disclaims all warranties and representations in any content
        transmitted on or in connection with the Axis Research and Publishing
        Center FZCO Service or on sites that may appear as links on the Axis
        Research and Publishing Center FZCO Service, or in the products provided
        as a part of, or otherwise in connection with, the Axis Research and
        Publishing Center FZCO Service, including without limitation any
        warranties of merchantability, fitness for a particular purpose or
        non-infringement of third party rights. No oral advice or written
        information given by Axis Research and Publishing Center FZCO or any of
        its affiliates, employees, officers, directors, agents, or the like will
        create a warranty. Price and availability information is subject to
        change without notice. Without limiting the foregoing, Axis Research and
        Publishing Center FZCO does not warrant that the Axis Research and
        Publishing Center FZCO Service will be uninterrupted, uncorrupted,
        timely, or error-free.
      </p>

      <h1 className={classes.title}>Copyright Disclaimer</h1>
      <p className={classes.description}>
        All intellectual property rights concerning these materials are vested
        in Axis Research and Publishing Center FZCO. Copying, distribution and
        any other use of these materials is not permitted without the written
        permission of Axis Research and Publishing Center FZCO, except and only
        to the extent otherwise provided in regulations of mandatory law (such
        as the right to quote), unless otherwise stated for certain materials.
      </p>

      <h1 className={classes.title}>Testimonials Disclosure</h1>
      <p className={classes.description}>
        Any testimonials provided on this platform are opinions of those
        providing them. The information provided in the testimonials is not to
        be relied upon to predict results in your specific situation. The
        results you experience will be dependent on many factors including but
        not limited to your level of personal responsibility, commitment, and
        abilities, in addition to those factors that you and/or Axis Research
        and Publishing Center FZCO may not be able to anticipate.
        <br />
        We will give honest testimonials to our visitors regardless of any
        discount. Any product or service that we test are individual
        experiences, reflecting real life experiences. The testimonials could be
        displayed on audio, text or video and are not necessarily representative
        of all of those who will use our products and/or services.
        <br />
        Axis Research and Publishing Center FZCO does not guarantee the same
        results as the testimonials given on our platform. Testimonials
        presented on Axis Research and Publishing Center FZCO are applicable to
        the individuals writing them, and may not be indicative of future
        success of any other individuals.
        <br />
        Please don’t hesitate to contact us if you would like to know more about
        testimonials, discounts, or any of the products/services that we review.
      </p>

      <h1 className={classes.title}>Your Consent</h1>
      <p className={classes.description}>
        We've updated our Disclaimer to provide you with complete transparency
        into what is being set when you visit our site and how it's being used.
        By using our website or making a purchase, you hereby consent to our
        Disclaimer and agree to its terms.
      </p>

      <h1 className={classes.title}>Changes to our Disclaimer</h1>
      <p className={classes.description}>
        Should we update, amend or make any changes to this document so that
        they accurately reflect our Service and policies. Unless otherwise
        required by law, those changes will be prominently posted here. Then, if
        you continue to use the Service, you will be bound by the updated
        Disclaimer. If you do not want to agree to this or any updated
        Disclaimer, you can cancel your subscription.
      </p>

      <h1 className={classes.title}>Contact Us</h1>
      <p className={classes.description}>
        Do not hesitate to contact us at support@heyaxis.com if you have any
        questions regarding this Disclaimer.
      </p>
      <br />
      <br />
    </div>
  );
};
export default Legal;
