import React from "react";
import classes from "./style.module.scss";
import axisLogo from "../../assets/axis-logo.svg";
import axisTitle from "../../assets/axis-title.svg";
import { Link } from "react-router-dom";
/*global fbq*/

const SuccessAnnually = () => {
  return (
    <div className={classes.container}>
      <head>
        <script type="text/javascript">
          {
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod
                  ? n.callMethod.apply(n, arguments)
                  : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = "2.0";
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(
              window,
              document,
              "script",
              "https://connect.facebook.net/en_US/fbevents.js"
            )
          }
          {fbq("init", "740203174334615")};{" "}
          {fbq("track", "Purchase", { currency: "USD", value: 29.99 })};
        </script>

        <noscript>
          <img
            height="1"
            width="1"
            alt=""
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=740203174334615&ev=PageView&noscript=1"
          />
        </noscript>
      </head>
      <div className={classes.bluebody}>
        <div className={classes.subscription}>
          <img className={classes.logosuccess} src={axisLogo} alt="axis" />
          <h1 className={classes.smallerfont}>Axis Stocks Feed</h1>
          <h1 className={classes.biggerfont}>14 days free</h1>
          <h1 className={classes.smallerfont}>
            Annual subscription $29.99 after trial period
          </h1>
        </div>
      </div>
      <div className={classes.whitebody}>
        <img src={axisTitle} alt="axis-logo" className={classes.logo} />
        <h1 className={classes.biggerfont}>Thank you for subscribing</h1>
        <p className={classes.smallerfont}>
          30 day guarantee. In case of cancellation within 30 days of
          subscribing, please contact support@heyaxis.com to use your right to a
          refund.
        </p>
        <Link to="/">
          <button className={classes.button}>Back to home</button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessAnnually;
