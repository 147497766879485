import React from "react";
import classes from "./style.module.scss";
import axisTitle from "../../assets/axis-title.svg";
import logo from "../../assets/axis-logo.svg";
import Iphone from "../../assets/Iphone.png";
import axisBody3 from "../../assets/axis-body-3.svg";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import whatsapp from "../../assets/whatsapp.png";
import whatsapp2 from "../../assets/whatsapp2.png";
import youtube from "../../assets/youtube.png";
import crown from "../../assets/Crown.png";
import performance1 from "../../assets/performance1.png";
import Tilray from "../../assets/Tilray.png";
import performance3 from "../../assets/performance3.png";
import { Link } from "react-router-dom";
import govdubai from "../../assets/govdubai.png";
import dsoa from "../../assets/DSOA.png";
import ifza from "../../assets/IFZA.png";
import refinitiv from "../../assets/Refinitiv.png";
import polygon from "../../assets/Polygon.png";
import cmc from "../../assets/CoinMarketCap.png";
import chatbox from "../../assets/chatbox.png";
import Carousel from "../../components/Carousel";
/*global fbq*/

const Home = () => {
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.header}>
          <img src={axisTitle} alt="axis-logo" className={classes.logo} />
        </div>
        <head>
          <script type="text/javascript">
            {
              !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                  n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
              })(
                window,
                document,
                "script",
                "https://connect.facebook.net/en_US/fbevents.js"
              )
            }
            {fbq("init", "740203174334615")};{fbq("track", "PageView")};
          </script>

          <noscript>
            <img
              height="1"
              width="1"
              alt=""
              style={{ display: "none" }}
              src="https://www.facebook.com/tr?id=740203174334615&ev=PageView&noscript=1"
            />
          </noscript>
        </head>

        <div className={classes.bluebody}>
          <img src={Iphone} alt="phone" className={classes.phone} />
          <div className={classes.bluearticle}>
            <h1 className={classes.bluetitle}>US Stocks Market Data</h1>
            <h1 className={classes.secondtitle}>
              Filtered
              <br />
              Redesigned
              <br />
              Simplified
            </h1>
            <p className={classes.subtitle}>
              Activate your investing ideas simply on
              <img
                src={whatsapp2}
                alt="whatsapp"
                className={classes["bluewhatsapp"]}
              />
              <br />
              <br />
              Subscribe to our US stocks feed now and{" "}
              <span className={classes["subbold"]}>pay later</span>!
            </p>

            <div className={classes["extrapadding"]}>
              <div className={classes["buttons-wrapper"]}>
                <button
                  id="annual"
                  content_id="222"
                  className={classes["annually-button"]}
                >
                  <div className={classes["child1"]}>
                    <a href="https://buy.stripe.com/dR602NdCd16WeqYaEJ">
                      <p>Annually -Billed at $29.99 </p>
                      <h1 className={classes["button-big"]}>
                        14 day free trial
                      </h1>
                      <p>$2.5 USD/month</p>
                    </a>
                  </div>
                  <div className={classes["child2"]}>
                    <p className={classes["savenow"]}>
                      save <span className={classes["subbold"]}>50%</span> now!
                    </p>
                  </div>
                </button>
                <p className={classes["smallspace"]}> </p>
                <button
                  id="monthly"
                  content_id="111"
                  className={classes["monthly-button"]}
                >
                  <a href="https://buy.stripe.com/8wMdTDcy9g1Q96EcMS">
                    <p className={classes["buttonbold"]}>Monthly</p>
                    <h1 className={classes["button-big"]}>7 day free trial</h1>
                    <p>$4.99 USD/month</p>
                  </a>
                </button>
                <p id="login" className={classes.login}>
                  &nbsp;Already a subscriber?{" "}
                  <a href="https://billing.stripe.com/p/login/dR6aHK4E10XCgSs288">
                    <span className={classes.underline}>Click here</span>
                  </a>
                </p>
              </div>
            </div>
            <p className={classes["refund"]}>
              <span className={classes["subbold"]}>30 day guarantee</span>{" "}
              <br />
              Full refund for cancellation within 30 days
            </p>
            <br />
          </div>

          <img src={axisBody3} alt="axisBody" className={classes.axisBody} />
        </div>
        <div className={classes.yellowbody}>
          <iframe
            className={classes.video}
            src="https://www.youtube.com/embed/IcvirpIg4u8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div className={classes.description}>
            <h1 className={classes.yellowtitle}>Get Axis Stocks Feed Every</h1>
            <br />
            <h3 className={classes.subtitle}>
              <div>
                <span className={classes["tick"]}>Sunday:&nbsp;</span>
                <span className={classes["subbold"]}>
                  to get axis to upcoming weekly earnings calls
                </span>{" "}
                <br />{" "}
              </div>
              <br />
              <br />
              <div>
                <span className={classes["tick"]}>Tuesday:&nbsp;</span>{" "}
                <span className={classes["subbold"]}>
                  to know more about the stocks' fundamentals
                </span>{" "}
                <br />{" "}
              </div>
              <br />
              <br />
              <div>
                {" "}
                <span className={classes["tick"]}>Thursday:&nbsp;</span>{" "}
                <span className={classes["subbold"]}>
                  10,000+ stocks filtered under different investment themes
                </span>{" "}
              </div>
            </h3>
            <br />
          </div>
        </div>
        <div className={classes.orangebody}>
          <div className={classes["title-box"]}>
            <h1 className={classes.orangetitle}>
              <img src={crown} alt="crown" className={classes["crown"]} />
              Our Top Picks
            </h1>
          </div>
          <div className={classes["top-picks-box"]}>
            <div className={classes["pick"]}>
              <img
                src={Tilray}
                alt="logo"
                className={classes["company-logo"]}
              />
              <p className={classes["company-name"]}>TLRY</p>
              <p className={classes["company-description"]}>
                Gained{" "}
                <span className={classes["boldcompanydescription"]}>
                  42% <span className={classes["greenarrow"]}>▲</span>
                </span>
              </p>
              <p className={classes["company-description"]}>in 4 days</p>
            </div>
            <div className={classes["pick"]}>
              <img
                src={performance3}
                alt="logo"
                className={classes["company-logo"]}
              />
              <p className={classes["company-name"]}>PL</p>
              <p className={classes["company-description"]}>
                Gained{" "}
                <span className={classes["boldcompanydescription"]}>
                  35% <span className={classes["greenarrow"]}>▲</span>
                </span>
              </p>
              <p className={classes["company-description"]}>in 4 days</p>
            </div>
            <div className={classes["pick"]}>
              <img
                src={performance1}
                alt="logo"
                className={classes["company-logo"]}
              />
              <p className={classes["company-name"]}>PDD</p>
              <p className={classes["company-description"]}>
                Gained{" "}
                <span className={classes["boldcompanydescription"]}>
                  58% <span className={classes["greenarrow"]}>▲</span>
                </span>
              </p>
              <p className={classes["company-description"]}>in 12 days</p>
            </div>
          </div>
          <br />
        </div>

        <div className={classes.purplebody}>
          <h1 className={classes.purpletitle}>
            <img src={chatbox} alt="chatbox" className={classes["chattitle"]} />
            Testimonials
          </h1>
          <Carousel />
        </div>

        <div className={classes.whitebody}>
          <p className={classes.subtitle}>Regulated by</p>
          <div className={classes.separator}>
            <img
              src={govdubai}
              alt="Government of Dubai"
              className={classes["regulations"]}
            />
            <img
              src={dsoa}
              alt="Dubai Silicon Oasis Authority"
              className={classes["regulations"]}
            />
            <img
              src={ifza}
              alt="International Free Zone Authority"
              className={classes["regulations"]}
            />
          </div>
          <p className={classes.subtitle}>Information Sources</p>
          <div className={classes.separator}>
            <img
              src={refinitiv}
              alt="Refinitiv"
              className={classes["sources"]}
            />
            <img src={polygon} alt="Polygon" className={classes["sources"]} />
            <img
              src={cmc}
              alt="Coin Market Cap"
              className={classes["sources"]}
            />
          </div>
        </div>

        <div className={classes.footer}>
          <img src={logo} alt="axis-logo" className={classes.logo} />

          {/* <div className={classes.info}>
            <p className={classes.text}>Learn</p>
            <p className={classes.text}>Subscriptions</p>
            <p className={classes.text}>FAQ</p>
          </div> */}
          <div className={classes.legal}>
            <Link to="/legal">
              <p className={classes.text}>Legal</p>
            </Link>
            <Link to="/privacy-policy">
              <p className={classes.text}>Privacy policy</p>
            </Link>
          </div>

          <div className={classes["socials-container"]}>
            <p className={classes.title}>&nbsp;&nbsp;Socials</p>
            <div className={classes["icons-wrapper"]}>
              <a
                href="https://www.instagram.com/hey.axis/"
                className={classes.icon}
              >
                <img src={instagram} alt="instagram" />
              </a>

              <a
                href="https://www.facebook.com/heyaxis/"
                className={classes.icon}
              >
                <img src={facebook} alt="facebook" />
              </a>

              <a
                href="https://twitter.com/hey_axis?s=11&t=WWb_IT9mjvv19Bq78pXaHg"
                className={classes.icon}
              >
                {" "}
                <img src={twitter} alt="twitter" />
              </a>

              <a href="https://wa.me/96541009612" className={classes.icon}>
                {" "}
                <img src={whatsapp} alt="whatsapp" />
              </a>
              <a
                href="https://www.youtube.com/embed/IcvirpIg4u8"
                className={classes.icon}
              >
                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
          <div className={classes.contacts}>
            <p className={classes.text}>Contact us</p>
            <p className={classes.text}>
              Axis Research and Publishing Center - FZCO DSO-IFZA,
            </p>
            <p className={classes.text}>Dubai 449631, UAE</p>
            <p className={classes.text}>support@heyaxis.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
